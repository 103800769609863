import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Img from 'gatsby-image';
import { graphql } from 'gatsby';

import SEO from '../components/SEO';
import { colors, font } from '../consts/style';
import { Container, Inner } from '../components/Elements';
import mq from '../style/mq';
import useTheme from '../hooks/useTheme';

const Wrapper = styled.div`
  background: white;
  ${Inner} {
    width: 120rem;
    max-width: 100%;
    padding: 8rem 0;

    ${mq.tabletSmall`
      padding: 6rem 0;
    `}
  }
`;

const Title = styled.h1`
  ${font.h1Post};
  margin-bottom: 1.2rem;
  text-transform: uppercase;
`;

const Short = styled.p`
  ${font.p};
  font-weight: bold;
  margin-bottom: 2.4rem;
`;

const FeateredImg = styled(Img)`
  margin-bottom: 2.4rem;
`;

const Content = styled.div`
  color: ${colors.darkBlue};
  p {
    ${font.p};
    margin-bottom: 1.6rem;
  }
  h1 {
    ${font.h1Post};
    margin-bottom: 1.6rem;
  }
  h2 {
    ${font.h2};
    margin-bottom: 1.6rem;
  }
  h3 {
    ${font.h3Post};
    margin-bottom: 1.6rem;
  }
  h4 {
    ${font.h4Post};
    margin-bottom: 1.6rem;
  }
  a {
    text-decoration: underline;
    font-weight: bold;
    color: ${colors.orange};
    &:hover {
      color: ${colors.orange};
    }
  }
  img {
    max-width: 100%;
    margin-bottom: 1.6rem;
  }

  ul,
  ol {
    ${font.p};
    margin-bottom: 1.6rem;
  }

  blockquote {
    background: rgba(0, 0, 0, 0.1);
    padding: 2.4rem;
    border-left: 0.8rem solid ${colors.yellow};
    margin-bottom: 1.6rem;
    p {
      ${font.pLarge};
      margin: 0;
    }
  }
`;

export default function BlogPost({ data: { blog } }) {
  const theme = useTheme('/post/');
  return (
    <Fragment>
      <SEO meta={blog.seoMetaTags} />
      <Wrapper>
        <Container>
          <Inner>
            <FeateredImg
              fluid={blog.featuredImage.fluid}
              backgroundColor={theme.color}
            />
            <Title>{blog.title}</Title>
            <Short>{blog.shortDescription}</Short>

            <Content dangerouslySetInnerHTML={{ __html: blog.content }} />
          </Inner>
        </Container>
      </Wrapper>
    </Fragment>
  );
}

BlogPost.propTypes = {
  data: PropTypes.object.isRequired,
};

export const blogQuery = graphql`
  query($slug: String!) {
    blog: datoCmsBlogPost(slug: { eq: $slug }) {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      title
      slug
      meta {
        firstPublishedAt(formatString: "MM/YYYY")
      }
      content
      headline
      featuredImage {
        fluid(maxWidth: 1200, imgixParams: { fm: "jpg", auto: "compress" }) {
          ...GatsbyDatoCmsFluid_noBase64
        }
      }
    }
  }
`;
